import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import BackgroundSlider from 'gatsby-image-background-slider'

export default ({ children }) => (
  <React.Fragment>
    {children}
    <BackgroundSlider 
      initDelay={8}
      transisiton={5}
      duration={12}
      style={{
        position: 'fixed',
        top: 0,
        zIndex: -1,
        filter:'brightness(65%)',
      }}
      query={useStaticQuery(graphql`
        query {
          backgrounds: allFile (filter: {sourceInstanceName: {eq: "backgrounds"},  name: {glob: "roofing*"}}){
            nodes {
              relativePath
              childImageSharp {
                fluid (maxWidth: 1920, quality: 94){
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      `)}
    />
  </React.Fragment>
);
