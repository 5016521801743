import React, {useState} from "react";
import SEO from "../components/seo";
import AboveFold from "../components/aboveFold";
import StakesBg from "../components/stakesRoofingBg";
import ContactForm from "../components/contactForm";
import Layout from "../components/layout";
import Hr from "../components/hr";
import PlanSteps from "../components/planSteps";
import Review from "../components/review";
import StyledBackgroundSection from "../components/backgroundRoofing";
import SectionHeading from "../components/sectionHeading";
import { 
  Button, 
  Container, 
  Row, 
  Col, 
  Card,
  CardGroup,
} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faArrowAltCircleUp, 
  faArrowAltCircleDown, 
  faCheckSquare, 
} from '@fortawesome/free-regular-svg-icons';
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
//import imgData from '../images/landmark/landmark-img-data.json'; // JSONData at buildtime

const Roofing = () => {
    const data = useStaticQuery(graphql`
    query {
      wideDivider1: file(relativePath: { eq: "roofing1.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      wideDivider2: file(relativePath: { eq: "roofing6-skylight.webp" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      wideDivider3: file(relativePath: { eq: "roofing5-zoom1.webp" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      wideDivider4: file(relativePath: { eq: "roofing8.webp" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      wideDivider5: file(relativePath: { eq: "roofing9.webp" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      wideDivider6: file(relativePath: { eq: "roofing11.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      stakes1: file(relativePath: { eq: "wp_water_damage_1.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      certainteedLogo: file(relativePath: { eq: "certainteed.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      certainteedBundle: file(relativePath: { eq: "certainteed_landmark_bundle.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      gafLogo: file(relativePath: { eq: "gaf.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      gafBundle: file(relativePath: { eq: "gaf_timberline_bundle.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      paragraph1: file(relativePath: { eq: "roofing12.webp" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      paragraph2: file(relativePath: { eq: "profile.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
        allLandmarkImgDataJson {
          edges {
            node {
              id
              name
              thumbnail {
                childImageSharp {
                  fixed(width: 48) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
              full {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
    }
  `);
  
                
  const [contactTxt] = useState('');
  
  // Single shingle style selected for detail display
  const [shingle, setShingle] = useState(undefined);
  
  // All available shingle styles (loaded from JSON via GraphQL)
  // This data structure is just repackaging the GraphQL result 
  const shingles = Object.values(data.allLandmarkImgDataJson.edges).map( (item,key) => {
      return {
        id: item.node.id,
        name: item.node.name,
        thumbnail: item.node.thumbnail.childImageSharp.fixed,
        full: item.node.full.childImageSharp.fluid,
      }
  });
  
  // The GraphQL GUID of the JSON objects is stored in the parent <div>
  // When the user clicks on the thumnail this function extracts the GUID from the data-target-id attribute
  // See https://developer.mozilla.org/en-US/docs/Learn/HTML/Howto/Use_data_attributes
  function handleClickThumbnail(e) {
    e.preventDefault();
    const targetId = e.target.parentElement.parentElement.parentElement.dataset.targetId;
    // Select the single matching item from all possible choices
    setShingle( shingles.find( (s) => s.id === targetId ));
  }
  
  return (
  <Layout>
    <SEO title="Roofing Contractor" path='roofing-contractor'/>
    <StyledBackgroundSection/>
    <AboveFold
      title='Roofing'
      subtitle='A&nbsp;new&nbsp;roof&nbsp;protects&nbsp;for decades&nbsp;and&nbsp;looks&nbsp;great'
      >
      {/* TODO LEADGEN 
      <div className="cg-leadLink">...or learn about <Link to="/common-roofing-and-siding-problems" className='cg-linkLight'>Common Roofing Problems</Link></div>
      */}
    </AboveFold>
    <Hr thick/>
    
    <main id='main'>
      <StakesBg id='stakes' className='cg-sectionShaded'>
        <div className='cg-section'>
          <Container fluid id='cg-stakesBox'>
            <Row>
              <Col>
                <SectionHeading>...but this can Destroy your house</SectionHeading>
              </Col>
            </Row>
            <Row>
              <Col sm style={{textAlign:'left'}}>
                <p>Not only is worn roofing ugly, but it also threatens home & health. 
                If the roof is damaged (or installed incorrectly), moisture can penetrate.</p>
                <p><strong>Moisture</strong> is the <strong>enemy</strong>:</p>
                <ul>
                  <li>Mold & mildew</li>
                  <li>Health hazards</li>
                  <li>Drywall & ceiling damage</li>
                  <li>Electric fire hazards</li>
                  <li>Structure & frame rot</li>
                </ul>
              </Col>
              <Col sm>
                <div role="img" aria-labelledby="stakes1Image">
                  <Img fluid={data.stakes1.childImageSharp.fluid} className="cg-picture"/>
                  <p id='stakes1Image' className='cg-caption'>Ceiling water damage</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>Delayed maintenance or poor roofing installation <strong>risks&nbsp;thousands</strong>.</p>
                <p>Protect and beautify your home by contacting us for&nbsp;a&nbsp;quote&nbsp;now:</p>
                <Button className="cg-dark" href="#contactForm" >Get a Quote</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </StakesBg>
      
      <hr/>
      
      <div id='value' className='cg-sectionLight'>
        <div className='cg-section'>
          <Container fluid>
            <Row>
              <Col>
                <SectionHeading>Quality Found Here</SectionHeading>
                <p>
                  When you choose MLB Contracting to install roofing, you get <strong>quality materials</strong> that
                  are <strong>installed right</strong> to make your home <strong>look&nbsp;great</strong>.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <CardGroup>
                  <Card>
                    <Card.Body>
                      <Card.Title>Trusted Materials</Card.Title>
                      <Card.Text>
                        Your house will be protected for decades with the best brand in roofing, covered by a solid manufacturer warranty.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                  <Card>
                    <Card.Body>
                      <Card.Title>Certified Installation</Card.Title>
                      <Card.Text>
                        Good materials don't work if they're installed wrong. We have the training, certification and experience to get your <strong>job done right</strong>.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                  <Card>
                    <Card.Body>
                      <Card.Title>A Beautiful Home</Card.Title>
                      <Card.Text>
                        You'll smile at the fresh curb-appeal that will greet you every time you come home. (Your neighbors might thank you, too.)
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </CardGroup>
              </Col>
            </Row>
            
            <Row>
              <Col style={{ paddingTop:'2rem' }} sm>
                <Button className="cg-dark" href="#contactForm" >Get a Quote</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
        
      <Hr img={data.wideDivider2.childImageSharp.fluid} />
      
      <div id='guide' style={{ textAlign:'center' }}  className='cg-sectionShaded'>
        <div className='cg-section'>
          <SectionHeading>Choosing the Right Contractor</SectionHeading>
          <Container fluid>
            <Row>
              <Col>
                <p>We understand the uncertainty of choosing a roofing contractor.</p>
                <div style={{textAlign:'left', maxWidth:'28em', margin:'auto'}}>
                  <ul>
                    <li>Will they charge a fair price?</li>
                    <li>Will their work will stand the test of time?</li>
                    <li>Will my house look nice when the job is done?</li>
                  </ul>
                </div>
                <p>
                  Unfortunately, we know <strong>these problems exist</strong>. That's 
                  why we've invested our time and money in becoming CertainTeed and GAF
                  certified roofing installers: so you can <strong>continue with confidence</strong>. 
                  It's also why we've collected reviews from our customers over 
                  the past {new Date().getFullYear() - 2005} years of service in
                  the Utica / Rome area: so you can be certain of the quality
                  service&nbsp;you&nbsp;will&nbsp;get.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <CardGroup>
                  <Review title='Outstanding!' name='John C.' thumbtack>
                    Put in new roof, siding, and tiled house. Did an <strong>outstanding job</strong>! Thank&nbsp;you!
                  </Review>
                  <Review title='Done in no time' name='John S.' thumbtack>
                    Replaced my whole roof and siding for a very decent price.
                    Came in, knocked the job off, and was <strong>done in no time</strong>. Excellent&nbsp;work. 
                  </Review>
                </CardGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <CardGroup>
                  <Review title='Would hire again' name='Samir'>
                    Great guys, great work, and affordable. Would hire again. Thanks!
                  </Review>
                  <Review title='Professional' name='Richard'>
                    Very professional. Good to work with and hard workers. Does not leave a mess behind. Highly&nbsp;recommend.
                  </Review>
                </CardGroup>
              </Col>
            </Row>
            <Row>
              <Col style={{ paddingTop:'2rem' }}>
                <p>You too, can freshen up your home with roofing&nbsp;that&nbsp;lasts.</p>
                <p>To get service like this <FontAwesomeIcon icon={faArrowAltCircleUp} /> contact&nbsp;us!&nbsp;<FontAwesomeIcon icon={faArrowAltCircleDown} /> </p>
              </Col>
            </Row>
            <Row>
              <Col style={{ paddingTop:'2rem' }} sm>
                <Link className='btn btn-secondary' to='/reviews#main'>More Reviews</Link>
              </Col>
              <Col style={{ paddingTop:'2rem' }} sm>
                <Button className="cg-dark" href="#contactForm" >Get a Quote</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      
      <Hr img={data.wideDivider3.childImageSharp.fluid} style={{ maxHeight: '30vh'}} 
        imgStyle={{objectPosition: 'bottom center', top:'initial', bottom:0 }}/>
        
      <div id='certainteed'  className='cg-sectionLight'>
        <div className='cg-section'>
          <Container fluid>
            <Row>
              <Col>
                <SectionHeading>Services</SectionHeading>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>Have a roofing problem? Solve it by calling us:</p>
                <div style={{margin:'auto', display:'inline-block', textAlign:'left'}}>
                  <dl>
                    <dt><Check/> New and replacement roofing</dt>
                    <dt><Check/> Chimney flashing repairs</dt>
                    <dt><Check/> Gutters installs</dt>
                    <dt><Check/> Ridge vent replacement</dt>
                    <dt><Check/> Soffit and fascia repair</dt>
                    <dt><Check/> Roof inspections</dt>
                  </dl>
                </div>
              </Col>
            </Row>
            <Row>
              <Col style={{ paddingTop:'2rem' }} sm>
                <Link className='btn btn-secondary' to='/portfolio-roofing'>Roofing Portfolio</Link>
              </Col>
              <Col style={{ paddingTop:'2rem' }} sm>
                <Button className="cg-dark" href="#contactForm" >Get a Quote</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      
      <Hr img={data.wideDivider4.childImageSharp.fluid} />
      
      <div id='certainteed'  className='cg-sectionShaded'>
        <div className='cg-section'>
          <Container fluid>
            <Row>
              <Col>
                <SectionHeading>Outstanding Premium Shingles</SectionHeading>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className='d-block d-sm-none cg-mini-picture-box'>
                  <Img fluid={data.certainteedLogo.childImageSharp.fluid} className='cg-picture'/>
                </div>
                <p>
                  The harsh winters of Central New York call for roofing that can 
                  handle snow, ice and sun. CertainTeed's <em>Landmark Pro</em> shingles answer that call 
                  with quality and durability. These shingles are certified as 
                  meeting the industry's toughest third-party (UL) manufacturing 
                  quality standards and have a Class A fire-resistance rating. 
                  Guaranteed to last, all <em>Landmark Pro</em> shingles include CertainTeed’s 
                  industry-leading, lifetime-limited warranty and fifteen year 
                  StreakFighter algae-resistance warranty.
                </p>
              </Col>
              <Col sm={4} md={5} lg={6} xl={7} className='d-none d-sm-block'>
                <Img fluid={data.certainteedLogo.childImageSharp.fluid} className='cg-picture'/>
                <Img fluid={data.certainteedBundle.childImageSharp.fluid} className='cg-picture' style={{marginTop:'2rem', maxHeight: 140}}/>
              </Col>
            </Row>
            <Row>
              <Col>
                <Img fluid={data.certainteedBundle.childImageSharp.fluid} 
                className='cg-picture d-block d-sm-none' 
                style={{marginTop:0, maxHeight: '12vh', width:'100%'}}
                imgStyle={{objectPosition: 'center left' }}
                />
              </Col>
            </Row>
            <Row>
              <Col sm style={{marginTop:'2rem'}}>
                <h3>Color Selections</h3>
              </Col>
            </Row>
            <Row>
              <Col sm>
                <p>
                  {/*
                  What else could you want from your roofing besides tenacious durability?
                  If you're like many homeowners, you want your roof to look nice, 
                  too.
                  */}
                  <em>Landmark Pro</em> shingles come in 20 eye-catching colors to compliment your home.
                </p>
                <p>
                  These shingles are engineered with two laminated layers to replicate the dimensional 
                  appearance of true wood shake. 
                  {/*Take a look at color options.*/}
                </p>
              </Col>
              <Col sm={7}>
                <div className="cg-thumbnail-container-roofing">
                  {shingles.map( (item,key) =>
                  <button
                    key={item.id} 
                    onClick={handleClickThumbnail}
                    data-target-id={item.id /* GUID stored here for lookup in click handler */}
                    >
                    <Img 
                      fixed={item.thumbnail}
                      title={item.name}
                      alt={item.name}
                      />
                  </button>
                  )}
                </div>
                <p className='cg-caption'>Click or touch for detail</p>
              </Col>
            </Row>

  
            {!!shingle &&
            <Row>
              <Col>
                <h3>{shingle.name}</h3>
                <Img fluid={shingle.full} title={shingle.name} alt={shingle.name}/>
              </Col>
            </Row>
            }
            
            <Row>
              <Col sm style={{marginTop:'2rem'}}>
                <p>
                  Uncertain which shingle color would best compliment your 
                  home?  We've been helping homeowners make great
                  style decisions for years. <a href="#contactForm" className='cg-linkDark'>Contact us</a> - we want your house to look&nbsp;it's&nbsp;best.
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm style={{marginTop:'2rem'}}>
                <Button variant='secondary' href="/LandmarkPro_DataSheet.pdf" >Technical Data Sheet</Button>
              </Col>
              <Col sm style={{marginTop:'2rem'}}>
                <Button className="cg-dark" href="#contactForm" >Get a Quote on CertainTeed Installation</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      
      {/*
      <div id='gaf'  className='cg-sectionLight'>
        <div className='cg-section'>
          <Container fluid>
            <Row>
              <Col>
                <SectionHeading>Quality Affordable Shingles</SectionHeading>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className='d-block d-sm-none cg-mini-picture-box'>
                  <Img fluid={data.gafLogo.childImageSharp.fluid} className='cg-picture'/>
                </div>
                <p>
                  If <a href="#certainteed" className='cg-linkDark'>CertainTeed</a> premium shingles are
                  beyond your budget, GAF <em>Timberline</em> shingles are a good choice. 
                  GAF is a best-selling shingle manufacturer that provides 
                  good asphalt shingles, as well as 
                  all other components of a complete roofing system: attic 
                  ventilation, ridge cap and starter strip shingles, roof deck 
                  protection, and leak barriers. Since we are certified GAF installers, you can be confident
                  that you'll have a long lasting roof protected by the GAF&nbsp;warranty.
                </p>
              </Col>
              <Col sm={3} md={2} lg={2} xl={2} className='d-none d-sm-block'>
                <Img fluid={data.gafLogo.childImageSharp.fluid} className='cg-picture'/>
              </Col>
            </Row>
            <Row>
              <Col>
                <Img fluid={data.gafBundle.childImageSharp.fluid} 
                  className='cg-picture' 
                  style={{marginTop:'0rem', maxHeight: '12vh', width:'100%'}}
                  imgStyle={{objectPosition: 'top right', top:0, bottom:'initial' }}
                  />
              </Col>
            </Row>
            <Row>
              <Col sm style={{marginTop:'2rem'}}>
                <Button className="cg-dark" href="#contactForm" >Get a Quote on GAF Installation</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      */}
      
      <Hr img={data.wideDivider5.childImageSharp.fluid} />
      
      <div id='plan'  className='cg-sectionShaded'>
        <div className='cg-section'>
          <Container fluid>
            <Row>
              <Col>
                <SectionHeading>Problem Solved</SectionHeading>
              </Col>
            </Row>
            <Row>
              <Col>
                <PlanSteps />
                <Button className="cg-dark" href="#contactForm">Get Started with a Quote</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      
      <Hr img={data.wideDivider6.childImageSharp.fluid} />
      
      <div id='paragraph' className='cg-sectionLight'>
        <div className='cg-section'>
          <Container fluid>
            <Row>
              <Col>
                <SectionHeading>About MLB&nbsp;Contracting</SectionHeading>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className='d-block d-sm-none cg-mini-picture-box'>
                  <Img fluid={data.paragraph2.childImageSharp.fluid} className='cg-picture'/>
                 </div>
                <p>
                  At MLB Contracting we know you want to feel good about the condition
                  of your house. A big part of this is maintaining the roof, which protects
                  the structure and interior from water damage. We believe every homeowner 
                  should be able to feel good about the appearance of their home and be
                  confident it will last. This is why we have focused, trained and been certified 
                  to install roofing according to industry standards. We have 
                  a <Link to="/reviews#main" className='cg-linkDark'>track record</Link> that 
                  ensures your success. We make it easy for you to protect your home,
                  while at the same time refreshing curb-appeal: 
                  1.Get a quote 2.We'll contact you to discuss project details 3. We'll 
                  schedule a start date that works for you. So,  <a href="#contactForm" className='cg-linkDark'>request a quote</a> today. 
                  {/* TODO LEADGEN 
                  And in the meantime, learn 
                  about <Link to="/common-roofing-and-siding-problems" className='cg-linkDark'>common roofing problems</Link>. 
                  */}
                  So you can stop worrying about water damage and instead enjoy a 
                  beautiful&nbsp;home.
                </p>
              </Col>
              <Col sm={4} md={5} lg={6} xl={7} className='d-none d-sm-block'>
               {/* <Img fluid={data.paragraph2.childImageSharp.fluid} className='cg-picture'/> */}
                <Img fluid={data.paragraph1.childImageSharp.fluid} className='cg-picture' style={{marginTop:'2rem'}}/>
              </Col>
            </Row>
            <Row>
              <Col sm style={{marginTop:'2rem'}}>
                <Link className='btn btn-secondary' to='/portfolio-roofing'>Roofing Portfolio</Link>
              </Col>
              <Col sm style={{marginTop:'2rem'}}>
                {/* TODO LEADGEN 
                <Button variant='secondary' href="/common-roofing-and-siding-problems" >Common Roofing Problems</Button>
                */}
                <Link className='btn btn-secondary' to='/reviews#main'>Reviews</Link>
                {/* TODO LEADGEN replace button above */}
              </Col>
              <Col sm style={{marginTop:'2rem'}}>
                <Button className="cg-dark" href="#contactForm" >Get a Quote</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      
      <Hr img={data.wideDivider1.childImageSharp.fluid}/>
      
    </main>
    <ContactForm message={contactTxt}/> 
    
        
    <div id='footnotes' className='cg-sectionShaded'>
      <div className='cg-section'>
        <Container fluid>
          <Row>
            <Col style={{ textAlign:'center', color:'#aaa'}}>
              <small>All construction photos are from MLB Contracting projects. Water damage photos are from Wikipedia. CertainTeed shingle option photos are from certainteed.com</small>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  </Layout>
)};

function Check () {
  return(
  <span style={{color:'#c77'}}><FontAwesomeIcon icon={faCheckSquare} /></span>
  );
}


export default Roofing;
